import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface CartDetail {
  menuId: number;
  menuName: string;
  thumbnailUrl: string;
  price: number;
  name: string;
  description: string;
  status: number;
  quantity: number;
  total_amount: number;
}

const initialState: CartDetail[] = [];

export const foodSlice = createSlice({
  name: "cartReducer",
  initialState,
  reducers: {
    addFood: (state, action: PayloadAction<CartDetail>) => {
      const foodIndex = state.findIndex((food) => food.name === action.payload.name && food.description === action.payload.description);
      if (foodIndex !== -1) {
        state[foodIndex].quantity += action.payload.quantity;
        state[foodIndex].total_amount += action.payload.total_amount;
      } else {
        state.push({ ...action.payload });
      }
    },
    increaseQuantity: (state, action: PayloadAction<CartDetail>) => {
      const foodItem = state.find((food) => food.name === action.payload.name && food.description === action.payload.description);
      if (foodItem) {
        foodItem.quantity++;
        foodItem.total_amount = foodItem.price * foodItem.quantity;
      }
    },
    decreaseQuantity: (state, action: PayloadAction<CartDetail>) => {
      const foodItem = state.find((food) => food.name === action.payload.name && food.description === action.payload.description);
      if (foodItem && foodItem.quantity !== 1) {
        foodItem.quantity--;
        foodItem.total_amount = foodItem.price * foodItem.quantity;
      } else {
        state.splice(
          state.findIndex((food) => food.name === action.payload.name && food.description === action.payload.description),
          1
        );
      }
    },
  
    removeFood: (state, action: PayloadAction<any>) => {
      return state.filter((food) => food.name !== action.payload.name);
    },
    reset: (state) => {
      return initialState;
    },
  },
});
export const getFoodListFromRedux = (rootState: RootState) => rootState.cart;
export const { addFood, increaseQuantity, decreaseQuantity, reset, removeFood } = foodSlice.actions;
export default foodSlice.reducer;
