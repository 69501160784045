import { createSlice } from "@reduxjs/toolkit";
import User from "../data/model/User";
import { RootState } from "./store";

const initialState: User = {
    uid: "",
    email: "",
    name: "",
    image: "",
    token: null,
    is_login: false
};

export const userSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        setUserLogin: (state, action: any) => {
            return {
                ...state,
                token: action.payload.token,
                is_login: true
            }
        },
        setUserLogout: state => {
            return {
                ...initialState
            }
        },
    }
})

export const selectuser = (rootState: RootState) => rootState.user;
export const selectUserIsLogin = (rootState: RootState) => selectuser(rootState).is_login;
export const { setUserLogin, setUserLogout } = userSlice.actions;
export default userSlice.reducer;