import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface SearchObject {
  isShowSearchBooking: boolean;
}

const initialState: SearchObject = {
  isShowSearchBooking: false
};

export const screenSlice = createSlice({
  name: "searchReducer",
  initialState,
  reducers: {
    onChangeShowSearchBooking: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isShowSearchBooking: action.payload.isShowSearchBooking,
      };
    },
    onResetSearch: (state) => {
      return initialState;
    },
  },
});
export const getSearchBookingFromRedux = (rootState: RootState) => rootState.search;
export const isShowSearchBooking = (rootState: RootState) => getSearchBookingFromRedux(rootState).isShowSearchBooking;
export const { onChangeShowSearchBooking, onResetSearch } = screenSlice.actions;
export default screenSlice.reducer;
