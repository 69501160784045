import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface DrawerObject {
  isShowDrawerBooking: boolean;
  isShowDrawerCart?: boolean;
}

const initialState: DrawerObject = {
  isShowDrawerBooking: false,
  isShowDrawerCart: false
};

export const screenSlice = createSlice({
  name: "drawerReducer",
  initialState,
  reducers: {
    onChangeShowDrawerBooking: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isShowDrawerBooking: action.payload.isShowDrawerBooking,
      };
    },
    onChangeShowDrawerCart: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        isShowDrawerCart: action.payload.isShowDrawerCart,
      };
    },
    onResetDrawer: (state) => {
      return initialState;
    },
  },
});
export const getDrawerBookingFromRedux = (rootState: RootState) => rootState.drawer;
export const isShowDrawerBooking = (rootState: RootState) => getDrawerBookingFromRedux(rootState).isShowDrawerBooking;
export const isShowDrawerCart = (rootState: RootState) => getDrawerBookingFromRedux(rootState).isShowDrawerCart;
export const { onChangeShowDrawerBooking, onChangeShowDrawerCart, onResetDrawer } = screenSlice.actions;
export default screenSlice.reducer;
