import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { persistReducer } from "redux-persist";
import localStorage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import cartReducer from "../redux/cartSlice";
import drawerReducer from "../redux/drawerSlice";
import searchReducer from "../redux/searchSlice";
import themeReducer from "../redux/themeSlice";
import userReducer from "../redux/userSlice";

const sessionPersistConfig = {
  key: "drawer",
  storage: storageSession,
  whitelist: ["drawer", "search"],
  blacklist: ["user", "theme", "cart"],
};

const localPersistConfig = {
  key: "root",
  storage: localStorage,
  whitelist: ["user", "theme", "cart"],
  blacklist: ["drawer", "search"],
};
const rootReducer = combineReducers({
  user: userReducer,
  theme: themeReducer,
  cart: cartReducer,
  drawer: persistReducer(sessionPersistConfig, drawerReducer),
  search: persistReducer(sessionPersistConfig, searchReducer),
});

const persistedReducer = persistReducer(localPersistConfig, rootReducer);

export type RootState = ReturnType<typeof rootReducer>;
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false });
  },
});
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export default store;
