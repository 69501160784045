import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { URL_ADMIN } from "./constants/common.const";

export const App: React.FC = () => {

    return (
        <div className="App">
            <Suspense fallback={<></>}>
                <Switch>
                    <Route path={URL_ADMIN} component={lazy(() => import('./pages/admin'))}/>
                    <Route path={''} component={lazy(() => import('./pages/common'))}/>
                </Switch>
            </Suspense>
        </div>
    );
}

export default App;
