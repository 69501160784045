import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { persistStore } from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react';
import { App } from './App';
import './index.css';
import store from "./redux/store";
import { initialState } from "./redux/themeSlice";
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const queryClient = new QueryClient()

let persistor = persistStore(store);
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <QueryClientProvider client={queryClient}>
                    <ThemeSwitcherProvider themeMap={themes} defaultTheme={initialState.currentTheme}
                                           insertionPoint="styles-insertion-point">
                        <App/>
                    </ThemeSwitcherProvider>
                </QueryClientProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
