import Lang, { PATH } from "data/model/constant";

export const URL_HOME = "/";
export const URL_HOMEPAGE = "/home/";
export const URL_HOMEPAGE_VI = "/trang_chu/";

export const URL_ADMIN = "/admin/";

export const URL_ABOUT = "/about/";
export const URL_ABOUT_VI = "/ve_chung_toi/";

export const URL_MENU = "/menu/";
export const URL_MENU_VI = "/thuc_don/";

export const URL_PROMOTION = "/promotion/";
export const URL_PROMOTION_VI = "/khuyen_mai/";

export const URL_BLOG = "/blog/";
export const URL_BLOG_VI = "/tin_tuc/";

export const URL_RECRUITMENT = "/recruitments/";
export const URL_RECRUITMENT_VI = "/tuyen_dung/";

export const URL_CONTACT = "/contact/";
export const URL_CONTACT_VI = "/lien_he/";

export const URL_VISITORS = "/visitor/";
export const URL_VISITORS_VI = "/khach_doan/";

export const URL_ARCHIVE_FOOD = "/archive_food/";
export const URL_ARCHIVE_FOOD_VI = "/mon_an/";

export const URL_PAYMENT = "/payment/";
export const URL_PAYMENT_VI = "/thanh_toan/";

export const URL_CART = "/cart/";
export const URL_CART_VI = "/gio_hang/";

export const URL_PAYMENT_CREATE = "/payment/create";

export const URL_PAYMENT_CALLBACK = "/payment/callback";
export const URL_PAYMENT_CALLBACK_VI = "/payment/callback";

export const URL_GUIDE_RESERVATION = "/guide_reservation/";
export const URL_GUIDE_RESERVATION_VI = "/huong_dan_dat_hang/";

export const URL_TERMS_OF_USE = "/terms_of_use";
export const URL_TERMS_OF_USE_VI = "/dieu_khoan_su_dung";

export const URL_PRIVACY_POLICY = "/privacy_policy/";
export const URL_PRIVACY_POLICY_VI = "/chinh_sach_bao_mat/";

export const URL_FEE_SHIPPING = "/fee_shipping/";
export const URL_FEE_SHIPPING_VI = "/phi_giao_hang/";

export const URL_LIST: PATH[] = [
  {
    callback: {
      Vi: URL_PAYMENT_CALLBACK_VI,
      En: URL_PAYMENT_CALLBACK,
    },
    home: {
      Vi: URL_HOMEPAGE_VI,
      En: URL_HOMEPAGE,
    },
    trang_chu: {
      Vi: URL_HOMEPAGE_VI,
      En: URL_HOMEPAGE,
    },
    thanh_toan: {
      Vi: URL_PAYMENT_VI,
      En: URL_PAYMENT,
    },
    payment: {
      Vi: URL_PAYMENT_VI,
      En: URL_PAYMENT,
    },
    about: {
      Vi: URL_ABOUT_VI,
      En: URL_ABOUT,
    },
    ve_chung_toi: {
      Vi: URL_ABOUT_VI,
      En: URL_ABOUT,
    },
    menu: {
      Vi: URL_MENU_VI,
      En: URL_MENU,
    },
    thuc_don: {
      Vi: URL_MENU_VI,
      En: URL_MENU,
    },
    blog: {
      Vi: URL_BLOG_VI,
      En: URL_BLOG,
    },
    tin_tuc: {
      Vi: URL_BLOG_VI,
      En: URL_BLOG,
    },
    recruitments: {
      Vi: URL_RECRUITMENT_VI,
      En: URL_RECRUITMENT,
    },
    tuyen_dung: {
      Vi: URL_RECRUITMENT_VI,
      En: URL_RECRUITMENT,
    },
    contact: {
      Vi: URL_CONTACT_VI,
      En: URL_CONTACT,
    },
    lien_he: {
      Vi: URL_CONTACT_VI,
      En: URL_CONTACT,
    },
    visitor: {
      Vi: URL_VISITORS_VI,
      En: URL_VISITORS,
    },
    khach_doan: {
      Vi: URL_VISITORS_VI,
      En: URL_VISITORS,
    },
    archive_food: {
      Vi: URL_ARCHIVE_FOOD_VI,
      En: URL_ARCHIVE_FOOD,
    },
    mon_an: {
      Vi: URL_ARCHIVE_FOOD_VI,
      En: URL_ARCHIVE_FOOD,
    },
    cart: {
      Vi: URL_CART_VI,
      En: URL_CART,
    },
    gio_hang: {
      Vi: URL_CART_VI,
      En: URL_CART,
    },
    guide_reservation: {
      Vi: URL_GUIDE_RESERVATION_VI,
      En: URL_GUIDE_RESERVATION,
    },
    huong_dan_dat_hang: {
      Vi: URL_GUIDE_RESERVATION_VI,
      En: URL_GUIDE_RESERVATION,
    },
    terms_of_use: {
      Vi: URL_TERMS_OF_USE_VI,
      En: URL_TERMS_OF_USE,
    },
    dieu_khoan_su_dung: {
      Vi: URL_TERMS_OF_USE_VI,
      En: URL_TERMS_OF_USE,
    },
    privacy_policy: {
      Vi: URL_PRIVACY_POLICY_VI,
      En: URL_PRIVACY_POLICY,
    },
    chinh_sach_bao_mat: {
      Vi: URL_PRIVACY_POLICY_VI,
      En: URL_PRIVACY_POLICY,
    },
    fee_shipping: {
      Vi: URL_FEE_SHIPPING_VI,
      En: URL_FEE_SHIPPING,
    },
    phi_giao_hang: {
      Vi: URL_FEE_SHIPPING_VI,
      En: URL_FEE_SHIPPING,
    },
    promotion: {
      Vi: URL_PROMOTION_VI,
      En: URL_PROMOTION,
    },
    khuyen_mai: {
      Vi: URL_PROMOTION_VI,
      En: URL_PROMOTION,
    },
  },
];

export enum LanguageCodes {
  Vietnam = "vi",
  English = "en",
}

// language headers code
export const ApiLanguageMapping = {
  [LanguageCodes.Vietnam.toString()]: "Vietnam",
  [LanguageCodes.English.toString()]: "English",
};

export const DEFAULT_LANGUAGE = LanguageCodes.Vietnam.toString();

export const LanguageList: Lang[] = [
  {
    langName: "English",
    langId: LanguageCodes.English.toString(),
    // icon: "/img/flags/usa.png",
    icon: "/logo/english.png",
    lang: "En",
    path: URL_LIST,
  },
  {
    langName: "Tiếng Việt",
    langId: LanguageCodes.Vietnam.toString(),
    // icon: "/img/flags/vi.png",
    icon: "/logo/vietnam.png",
    lang: "Vi",
    path: URL_LIST,
  },
];

export const LanguageKeyRequest = "Language";

export const BASE_API = "/api";
export const HOMEPAGE_API = "/home-metadata";
export const INIT_CREDENTIAL = {
  username: "superAdmin",
  password: "test@123",
};

export const HOANGTAM_PHONE = "028 6670 2121";
export const HOANGTAM_EMAIL = "contact@nhahanghoangtam.vn";

export const TAX_VAT = 8;
export const TAX_VAT_PERCENT = 0.08;

export const TRANSFER_FEE = 20000;
export const PAGINATION_PAGE_SIZE = 5;
export const rules: any = {
  name: [
    {
      required: true,
      message: "Please enter this field",
    },
    { min: 3, message: "Field must be minimum 5 characters." },
  ],
  name_10: [
    {
      required: true,
      message: "Please enter this field",
    },
    { max: 10, message: "Field must be maximum 10 characters." },
  ],
  name_100: [
    {
      required: true,
      message: "Please enter this field",
    },
    { max: 100, message: "Field must be maximum 100 characters." },
  ],
  name_5000: [
    {
      required: true,
      message: "Please enter this field",
    },
    { max: 5000, message: "Field must be maximum 5000 characters." },
  ],
  numberFormat: [
    () => ({
      validator(_: any, value: any) {
        if (isNaN(value)) {
          return Promise.reject("Please enter number");
        }
        return Promise.resolve();
      },
    }),
  ],
  numberFormatRequire: [
    {
      required: true,
      message: "Please enter this field",
    },
    () => ({
      validator(_: any, value: any) {
        if (isNaN(value)) {
          return Promise.reject("Please enter number");
        }
        return Promise.resolve();
      },
    }),
  ],
  email: [
    {
      required: true,
      type: "email",
      message: "Please enter email",
    },
  ],
  emailNonRequire: [
    {
      required: false,
      type: "email",
      message: "Please enter email",
    },
  ],
  textRequire: [
    {
      required: true,
      whitespace: true,
      message: "Please enter this field",
    },
  ],
  dateTimePicker: [{ type: "object" as const, required: true, message: "Please select time!" }],
  base_name: [
    {
      validator: (_: any, value: any) => {
        if (REGEX_BASENAME_FORMAT.test(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject("Sai format!");
        }
      },
    },
    { max: 100, message: "Field must be maximum 100 characters." },
  ],
};

export const STATUS_FOOD = [
  {
    key: null,
    value: "",
  },
  {
    key: 0,
    value: "Mặc định",
  },
  {
    key: 1,
    value: "Best Seller",
  },
];

export const ADMIN_PAYMENT_PAYSTATUS = [
  {
    key: "Paid",
    value: "Đã thanh toán",
  },
  {
    key: "UnPaid",
    value: "Chưa thanh toán",
  },
  {
    key: "Invalid",
    value: "Không hợp lệ",
  },
  {
    key: "Failed",
    value: "Thất bại",
  },
];

export const ADMIN_PAYMENT_STATUS = [
  {
    key: "Success",
    value: "Thành công",
  },
  {
    key: "Failed",
    value: "Thất bại",
  },
  {
    key: "Cancelled",
    value: "Hủy",
  },
];
export const TAX_8 = 8;
export const TAX_10 = 10;
export const PAYMENT_METHOD_DIRECTLY = "Directly";
export const PAYMENT_METHOD_VNPAY = "Vnpay";
export const VNPAY_METHOD_VNPAYQR = "VNPAYQR";
export const VNPAY_METHOD_VNBANK = "VNBANK";
export const VNPAY_METHOD_INTCARD = "INTCARD";
export const PAYSTATUS_PAID = "Paid";
export const PAYSTATUS_UNPAID = "UnPaid";
export const PAYSTATUS_FAILED = "Failed";
export const PAYMENT_DELIVERED_STATUS = "Delivered";

export const PAYLOAD_HOMEPAGE_ABOUT_TYPE = {
  mainKey: "general",
  sectionKey: "about",
  fieldKey: "thumbnailUrl",
};
export const PAYLOAD_HOMEPAGE_BANNER_TYPE = {
  mainKey: "home",
  sectionKey: "banner",
  fieldKey: "url",
};
export const REGEX_IMAGE_FORMAT: any = /\.(jpe?g|png|webp)$/;
export const REGEX_PDF_FORMAT: any = /\.(pdf)$/;
export const REGEX_VIDEO_LINK_FORMAT = /^https?:\/\/(.*)/;
// export const REGEX_BASENAME_FORMAT = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
export const REGEX_BASENAME_FORMAT = /^[a-z0-9]+(-[a-z0-9]+)+$/;

export const MENU_LIST_PUBLISH_LIMITED = 15
export const PROMOTION_PAGE_DELAY = 5000;
export const MENU_PAGE_IMAGE_DELAY = 800;
export const BRANCH_NCC = {
  locationLink: "https://g.page/hoangtamnguyencanhchan?share",
  address: "TK22/1 - TK22/6 Nguyễn Cảnh Chân, P.Cầu Kho, Q1",
  phoneText: "0773.998.883",
  phone: "tel:0773998883",
  email: "ncc@nhahanghoangtam.vn"
};
export const BRANCH_VH = {
  locationLink: "https://g.page/hoangtamvinhomescentralpark?share",
  address: "P1-SH04 (Park 1) Vinhomes Central Park, Q.Bình Thạnh",
  phoneText: "0901.491.661",
  phone: "tel:0901491661",
  email: "vh@nhahanghoangtam.vn "
};
export const BRANCH_PXL = {
  locationLink: "https://g.page/hoangtamphanxichlong?share",
  address: "352 Phan Xích Long, P.2, Q.Phú Nhuận",
  phoneText: "0938.418.883",
  phone: "tel:0938418883",
  email: "pxl@nhahanghoangtam.vn "
};

export const BRANCH_PMH = {
  locationLink: "https://g.page/hoangtamphumyhung?share",
  address: "24 Raymondienne, P.Tân Phú, Q.7",
  phoneText: "0969.002.200",
  phone: "tel:0969002200",
  email: "pmh@nhahanghoangtam.vn"
};
export const BRANCH_SH = {
  locationLink: "https://g.page/hoangtamsonghanh?share",
  address: "47 Song Hành, P.An Phú, Q.2",
  phoneText: "0779.098.883",
  phone: "tel:0779098883",
  email: "sh@nhahanghoangtam.vn"
};

export const DOC_EXTENTION = ["pdf", "docx", "doc"]

export const TIME_ORDER_FROM_TO = {
  from: 10,
  to: 21,
};